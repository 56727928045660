import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeArticleSkeleton } from "./TypeArticle";
import type { TypePageSkeleton } from "./TypePage";
import type { TypeProjectSkeleton } from "./TypeProject";
import type { TypeReportSkeleton } from "./TypeReport";

export type TypeCompanyFields = {
  name: EntryFieldTypes.Symbol;
  slug: EntryFieldTypes.Symbol;
  ticker?: EntryFieldTypes.Symbol;
  isin?: EntryFieldTypes.Symbol;
  hq?: EntryFieldTypes.Symbol;
  marketCap?: EntryFieldTypes.Number;
  primaryMarkets?: EntryFieldTypes.Array<EntryFieldTypes.Symbol<"Africa" | "Asia" | "Europe & Russia" | "LATAM" | "MENA" | "North America" | "Oceania">>;
  industry?: EntryFieldTypes.Symbol<"Agrochemical" | "Fertiliser" | "Manufacturer" | "Pharmaceutical" | "Protein Producer" | "Restaurant" | "Retailer" | "Specialty Ingredients">;
  logo?: EntryFieldTypes.AssetLink;
  projects?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeProjectSkeleton>>;
  relatedContent?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeArticleSkeleton | TypePageSkeleton | TypeReportSkeleton>>;
};

export type TypeCompanySkeleton = EntrySkeletonType<TypeCompanyFields, "company">;
export type TypeCompany<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeCompanySkeleton, Modifiers, Locales>;

export function isTypeCompany<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeCompany<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "company";
}
